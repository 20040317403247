import {
  AccountCircleOutlined,
  CalendarMonthOutlined,
} from "@mui/icons-material";
import { Card, Box } from "@mui/material";
import React from "react";
import {
  AppointmentDetailLabel,
  AppointmentDetailValue,
} from "./AppointmentDetail";
import { PaymentFormCardTitle } from "./PaymentForm";
import { IBooking } from "@/interfaces/IBooking";
import moment from "moment";

type PatientDetailProps = {
  patient?: IBooking["patient"] | null;
};

const PatientDetail = (props: PatientDetailProps) => {
  return (
    <Card
      sx={(theme) => ({
        display: "flex",
        flexDirection: "column",
        p: "1.5rem",
      })}
    >
      <PaymentFormCardTitle
        Icon={
          <AccountCircleOutlined
            sx={{
              width: "1.25rem",
              height: "1.25rem",
            }}
            color="primary"
          />
        }
        title="Patient Details"
      />
      <Box
        sx={{
          display: "flex",
          // flexDirection: "column",
          gap: ".5rem",
          px: "2rem",
          py: ".5rem",
          flex: 4,
          // backgroundColor: "#f5f5f5",
          // borderRadius: "5px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            // padding: "1rem",
            flex: 2,
            // backgroundColor: "#f5f5f5",
            // borderRadius: "5px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <AppointmentDetailLabel gutterBottom variant="body2">
              Full Name
            </AppointmentDetailLabel>
            <AppointmentDetailValue variant="body2">
              {`${props.patient?.firstName ?? ""} ${
                props.patient?.lastName ?? ""
              }`}
            </AppointmentDetailValue>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <AppointmentDetailLabel gutterBottom variant="body2">
              Gender
            </AppointmentDetailLabel>
            <AppointmentDetailValue variant="body2">
              {props.patient?.gender ?? "n/a"}
            </AppointmentDetailValue>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <AppointmentDetailLabel gutterBottom variant="body2">
              Address
            </AppointmentDetailLabel>
            <AppointmentDetailValue variant="body2">
              {props.patient?.addressLineOne ?? "n/a"}
            </AppointmentDetailValue>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <AppointmentDetailLabel gutterBottom variant="body2">
              Contact Number
            </AppointmentDetailLabel>
            <AppointmentDetailValue variant="body2">
              {props.patient?.phoneNumber ?? "n/a"}
            </AppointmentDetailValue>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            // padding: "1rem",
            flex: 2,
            // backgroundColor: "#f5f5f5",
            // borderRadius: "5px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <AppointmentDetailLabel gutterBottom variant="body2">
              Date of Birth
            </AppointmentDetailLabel>
            <AppointmentDetailValue variant="body2">
              {moment(props.patient?.dateOfBirth).format("MMM DD, YYYY") ??
                "n/a"}
            </AppointmentDetailValue>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <AppointmentDetailLabel gutterBottom variant="body2">
              Email
            </AppointmentDetailLabel>
            <AppointmentDetailValue variant="body2">
              {props.patient?.emailAddress ?? "n/a"}
            </AppointmentDetailValue>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <AppointmentDetailLabel gutterBottom variant="body2">
              Post Code
            </AppointmentDetailLabel>
            <AppointmentDetailValue variant="body2">
              {props.patient?.postCode ?? "n/a"}
            </AppointmentDetailValue>
          </Box>
          {/* <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <AppointmentDetailLabel >Notes</AppointmentDetailLabel>
            <AppointmentDetailValue >
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Soluta
              adipisci iusto, numquam necessitatibus error cupiditate beatae
              natus architecto vero vel molestias corrupti fuga ullam explicabo
              aut. Consequuntur ullam minima labore!
            </AppointmentDetailValue>
          </Box> */}
        </Box>
      </Box>
    </Card>
  );
};

export default PatientDetail;
