import { LazyQueryHookExecOptions, useLazyQuery } from "@apollo/client";
import {
  Exact,
  GetPharmacyQuery,
  GetPharmacyQueryVariables,
} from "../codegens/AIR-API/__generated__/graphql";
import { GET_PARMACY } from "../codegens/AIR-API/queries";
import { GRAPHQL_DESTINATION } from "../apollo-client";

export const usePharmacy = () => {
  const [fetchPharmacyQuery, { error, loading }] = useLazyQuery<
    GetPharmacyQuery,
    GetPharmacyQueryVariables
  >(GET_PARMACY, {
    context: {
      clientName: GRAPHQL_DESTINATION.AIR_API,
    },
  });

  const getPharmacy = (
    options: Partial<
      LazyQueryHookExecOptions<GetPharmacyQuery, Exact<{ id: string }>>
    >
  ) => {
    return fetchPharmacyQuery({
      ...options,
    });
  };

  return {
    getPharmacy,
    error,
    loading,
  };
};
