import { CalendarMonthOutlined } from "@mui/icons-material";
import { Box, Card, styled, Typography } from "@mui/material";
import React from "react";
import { PaymentFormCardTitle } from "./PaymentForm";
import { IBooking } from "@/interfaces/IBooking";
import { useApp } from "@/context/AppContext";
import { dateLib } from "@/lib/_calendar";

type AppointmentDetailProps = {
  appointment?: IBooking | null;
};

export const AppointmentDetailLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[500],
  fontSize: "0.8rem",
}));

export const AppointmentDetailValue = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: "0.93rem",
}));

const AppointmentDetail = (props: AppointmentDetailProps) => {
  const { selectedService, selectedPharmacy } = useApp();

  return (
    <Card
      sx={(theme) => ({
        display: "flex",
        flexDirection: "column",
        p: "1.5rem",
      })}
    >
      <PaymentFormCardTitle
        Icon={
          <CalendarMonthOutlined
            sx={{
              width: "1.25rem",
              height: "1.25rem",
            }}
            color="primary"
          />
        }
        title="Patient Details"
      />
      <Box
        sx={{
          display: "flex",
          // flexDirection: "column",
          gap: ".5rem",
          px: "2rem",
          py: ".5rem",
          flex: 4,
          // backgroundColor: "#f5f5f5",
          // borderRadius: "5px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "2rem",
            // padding: "1rem",
            flex: 2,
            // backgroundColor: "#f5f5f5",
            // borderRadius: "5px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <AppointmentDetailLabel gutterBottom>What</AppointmentDetailLabel>
            <AppointmentDetailValue variant="body2">
              {selectedService?.serviceName ?? "n/a"}
            </AppointmentDetailValue>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <AppointmentDetailLabel gutterBottom>Where</AppointmentDetailLabel>
            <AppointmentDetailValue variant="body2">
              {selectedPharmacy?.pharmacyName ?? "n/a"}
            </AppointmentDetailValue>
            <Typography variant="body2">
              {selectedPharmacy?.pharmacyAddress ?? "n/a"}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: ".5rem",
            // padding: "1rem",
            flex: 2,
            // backgroundColor: "#f5f5f5",
            // borderRadius: "5px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <AppointmentDetailLabel gutterBottom>When</AppointmentDetailLabel>
            <AppointmentDetailValue variant="body2">
              {dateLib(props.appointment?.timeslot).format(
                "dddd, MMMM Do YYYY, h:mm a"
              )}
            </AppointmentDetailValue>
          </Box>
        </Box>
      </Box>
    </Card>
  );
  // return (
  //   <Card
  //     sx={(theme) => ({
  //       display: "flex",
  //       flexDirection: "column",
  //       p: "1.5rem",
  //     })}
  //   >
  //     <PaymentFormCardTitle
  //       Icon={<CalendarMonthOutlined color="primary" />}
  //       title="Appointment Details"
  //     />
  //     <Box
  //       sx={{
  //         display: "flex",
  //         // flexDirection: "column",
  //         // gap: ".5rem",
  //         px: "1rem",
  //         // py: ".5rem",
  //         // backgroundColor: "#f5f5f5",
  //         // borderRadius: "5px",
  //       }}
  //     >
  //       <Box
  //         sx={{
  //           display: "flex",
  //           flexDirection: "column",
  //           gap: ".5rem",
  //           px: "1rem",
  //           // backgroundColor: "#f5f5f5",
  //           // borderRadius: "5px",
  //         }}
  //       >
  //         <Box
  //           sx={{
  //             display: "flex",
  //             flexDirection: "column",
  //           }}
  //         >
  //           <AppointmentDetailLabel>What</AppointmentDetailLabel>
  //           <AppointmentDetailValue>
  //             {selectedService?.serviceName ?? "n/a"}
  //           </AppointmentDetailValue>
  //         </Box>
  //         <Box
  //           sx={{
  //             display: "flex",
  //             flexDirection: "column",
  //           }}
  //         >
  //           <AppointmentDetailLabel>Where</AppointmentDetailLabel>
  //           <AppointmentDetailValue fontWeight="bold">
  //             {selectedPharmacy?.pharmacyName ?? "n/a"}
  //           </AppointmentDetailValue>
  //           <AppointmentDetailValue>
  //             {selectedPharmacy?.pharmacyAddress ?? "n/a"}
  //           </AppointmentDetailValue>
  //         </Box>
  //       </Box>
  //       <Box
  //         sx={{
  //           display: "flex",
  //           flexDirection: "column",
  //           gap: ".5rem",
  //           padding: "1rem",
  //           // backgroundColor: "#f5f5f5",
  //           // borderRadius: "5px",
  //         }}
  //       >
  //         <Box
  //           sx={{
  //             display: "flex",
  //             flexDirection: "column",
  //             gap: ".5rem",
  //           }}
  //         >
  //           <AppointmentDetailLabel>When</AppointmentDetailLabel>
  //           <AppointmentDetailValue>
  //             {dateLib(props.appointment?.timeslot).format(
  //               "dddd, MMMM Do YYYY, h:mm a"
  //             )}
  //           </AppointmentDetailValue>
  //         </Box>
  //       </Box>
  //     </Box>
  //   </Card>
  // );
};

export default AppointmentDetail;
