import MUITableLicensed from "@/components/MUITableLicensed/MUITableLicensed";
import {
  Box,
  TextField,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import {
  DataGridProProps,
  GridActionsCellItem,
  GridColDef,
  GridEditInputCell,
  GridEditInputCellProps,
  GridRenderEditCellParams,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
} from "@mui/x-data-grid-pro";
import moment from "moment";
import {
  CancelOutlined,
  DeleteOutline,
  Diversity3,
  EditOutlined,
  SaveOutlined,
} from "@mui/icons-material";
import { Dispatch, SetStateAction, useEffect } from "react";
import { IPatientClinicFields } from "@/interfaces/IAgedCare";

export interface IAgedCarePatientsTableProps
  extends Omit<DataGridProProps, "rows" | "columns"> {
  patients: IPatientClinicFields[];
  setPatients?: Dispatch<SetStateAction<IPatientClinicFields[]>>;
  setRowModesModel?: Dispatch<SetStateAction<GridRowModesModel>>;
  handleSaveClick?: (id: GridRowId) => void;
  handleCancelClick?: (id: GridRowId) => void;
  handleEditClick?: (id: GridRowId) => void;
  handleDeleteClick?: (id: GridRowId) => void;
}

const StyledBox = styled("div")(({ theme }) => ({
  height: 400,
  width: "100%",
  "& .MuiDataGrid-cell--editable": {
    backgroundColor: "rgb(217 243 190)",
    "& .MuiInputBase-root": {
      height: "100%",
    },
    ...theme.applyStyles("dark", {
      backgroundColor: "#376331",
    }),
  },
  "& .Mui-error": {
    backgroundColor: "rgb(126,10,15, 0.1)",
    color: "#750f0f",
    ...theme.applyStyles("dark", {
      backgroundColor: "rgb(126,10,15, 0)",
      color: "#ff4343",
    }),
  },
}));

const StyledGridOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.palette.grey[50],
  height: "100%",
  "& .ant-empty-img-1": {
    fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
  },
  "& .ant-empty-img-2": {
    fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
  },
  "& .ant-empty-img-3": {
    fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
  },
  "& .ant-empty-img-4": {
    fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
  },
  "& .ant-empty-img-5": {
    fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
    fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
  },
}));

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
}));

function NameEditInputCell(props: GridRenderEditCellParams) {
  const { error, message } = props;

  return (
    <StyledTooltip open={!!error} title={message}>
      <span>
        <GridEditInputCell {...props} />
      </span>
    </StyledTooltip>
  );
}

function renderEmptyValidationField(params: GridRenderEditCellParams) {
  return <NameEditInputCell {...params} />;
}

function DateEditInputCell(props: GridRenderEditCellParams) {
  const { error } = props;

  return (
    <StyledTooltip open={!!error} title={error}>
      <span>
        <GridEditInputCell {...props} placeholder="DD/MM/YYYY" />
      </span>
    </StyledTooltip>
  );
}

function renderEditDate(params: GridRenderEditCellParams) {
  return <DateEditInputCell {...params} />;
}

const validateName = (
  value: string,
  fieldLabel: string = "First Name"
): Promise<{
  isValid: boolean;
  message: string | null;
}> => {
  return new Promise<{
    isValid: boolean;
    message: string | null;
  }>((resolve) => {
    if (!value.trim()) {
      resolve({
        isValid: false,
        message: `${fieldLabel} is required.`,
      });
      return;
    }
    resolve({
      isValid: true,
      message: null,
    });
  });
};

const AgedCarePatientsTable = ({
  patients,
  setPatients,
  ...dataGridProps
}: IAgedCarePatientsTableProps) => {
  let promiseTimeout: ReturnType<typeof setTimeout> | undefined;

  useEffect(() => {
    return () => {
      clearTimeout(promiseTimeout);
    };
  }, []);

  const columns: GridColDef<IPatientClinicFields>[] = [
    {
      field: "firstName",
      headerName: "First Name",
      type: "string",
      flex: 1,
      editable: true,
      preProcessEditCellProps: async (params) => {
        const { isValid, message } = await validateName(
          params.props.value!.toString()
        );

        return { ...params.props, error: !isValid, message };
      },
      renderEditCell: renderEmptyValidationField,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      type: "string",
      flex: 1,
      editable: true,
      preProcessEditCellProps: async (params) => {
        const { isValid, message } = await validateName(
          params.props.value!.toString(),
          "Last Name"
        );
        return { ...params.props, error: !isValid, message };
      },
      renderEditCell: renderEmptyValidationField,
    },
    {
      field: "dateOfBirth",
      headerName: "Date Of Birth",
      flex: 1,
      editable: true,
      renderCell(params) {
        const dob = params.row.dateOfBirth
          ? moment(params.row.dateOfBirth).isValid()
            ? moment(params.row.dateOfBirth).format("DD/MM/YYYY")
            : params.row.dateOfBirth.toString()
          : "N/A";
        return <Typography display="contents">{dob}</Typography>;
      },
      preProcessEditCellProps: async (params) => {
        const dateStr = params.props.value?.toString() || "";
        const isValidFormat = /^(\d{2})\/(\d{2})\/(\d{4})$/.test(dateStr);
        const isValidDate = moment(dateStr, "DD/MM/YYYY", true).isValid();
        const isFutureDate = moment(dateStr, "DD/MM/YYYY").isAfter(moment());

        if (!dateStr.trim()) {
          return {
            ...params.props,
            error: true,
            message: "Date of birth is required",
          };
        }

        if (!isValidFormat) {
          return {
            ...params.props,
            error: true,
            message: "Use format DD/MM/YYYY",
          };
        }

        if (!isValidDate) {
          return {
            ...params.props,
            error: true,
            message: "Invalid date",
          };
        }

        if (isFutureDate) {
          return {
            ...params.props,
            error: true,
            message: "Date cannot be in the future",
          };
        }

        return {
          ...params.props,
          error: false,
          message: null,
        };
      },
      renderEditCell: renderEmptyValidationField,
    },
    {
      field: "medicareNumber",
      headerName: "Medicare Number",
      type: "string",
      editable: true,
      flex: 1,
      preProcessEditCellProps: async (params) => {
        const { isValid, message } = await validateName(
          params.props.value!.toString(),
          "Medicare Number"
        );
        return { ...params.props, error: !isValid, message };
      },
      renderEditCell: renderEmptyValidationField,
    },
    {
      field: "medicareIRN",
      headerName: "Medicare IRN",
      type: "string",
      editable: true,
      flex: 1,
      preProcessEditCellProps: async (params) => {
        const { isValid, message } = await validateName(
          params.props.value!.toString(),
          "Medicare IRN"
        );
        return { ...params.props, error: !isValid, message };
      },
      renderEditCell: renderEmptyValidationField,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      flex: 1,
      getActions: (props) => {
        const { id } = props;

        const isInEditMode =
          dataGridProps.rowModesModel?.[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveOutlined />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={() => {
                console.log("On save click ", dataGridProps);
                if (typeof dataGridProps.handleSaveClick === "function") {
                  dataGridProps.handleSaveClick(id);
                }
              }}
            />,
            <GridActionsCellItem
              icon={<CancelOutlined />}
              label="Cancel"
              className="textPrimary"
              onClick={() => {
                console.log("On cancel click ", dataGridProps);
                console.log(
                  "typeof props ",
                  typeof dataGridProps.handleCancelClick
                );

                if (typeof dataGridProps.handleCancelClick === "function") {
                  console.log("Should run parent method");
                  dataGridProps.handleCancelClick(id);
                }
              }}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditOutlined />}
            label="Edit"
            className="textPrimary"
            onClick={() => {
              console.log("On Edit click ", dataGridProps);

              if (typeof dataGridProps.handleEditClick === "function") {
                dataGridProps.handleEditClick(id);
              }
            }}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteOutline />}
            label="Delete"
            onClick={() => {
              console.log("On delete click", dataGridProps);

              if (typeof dataGridProps.handleDeleteClick === "function") {
                dataGridProps.handleDeleteClick(id);
              }
            }}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <StyledBox>
      <MUITableLicensed
        getRowId={(row) => row.clinicPatientID}
        columns={columns}
        rows={patients}
        disableRowSelectionOnClick
        editMode="row"
        autoHeight
        slots={{
          noRowsOverlay: () => (
            <StyledGridOverlay>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Diversity3
                  sx={(theme) => ({
                    color: theme.palette.grey[300],
                    width: "3rem",
                    height: "3rem",
                  })}
                />
                <Typography>No users to show</Typography>
              </Box>
            </StyledGridOverlay>
          ),
        }}
        {...dataGridProps}
      />
    </StyledBox>
  );
};

export default AgedCarePatientsTable;
